import React from 'react'
import { connect } from 'react-redux'
import { compose, get, isEmpty } from 'lodash/fp'

import { Flex } from 'src/components/system'
import { ButtonCircle } from 'src/components/ButtonCircle'
import { Link } from 'src/components/Link'
import { MagnetStrip } from 'src/components/MagnetStrip'

const SendButtonBase = ({ hasNoMagnets = true, ...props }) => (
  <Flex
    justifyContent="center"
    mx={1}
    mt={-1}
    position="relative"
    css={`
      &:hover {
        .emptyMessage {
          opacity: ${hasNoMagnets ? 1 : 0};
        }
      }
    `}
  >
    <ButtonCircle
      as={Link}
      to="/send/"
      onClick={e => hasNoMagnets && e.preventDefault()}
    >
      Send
    </ButtonCircle>
    <MagnetStrip
      className="emptyMessage"
      inverted={true}
      position="absolute"
      top="50%"
      left="50%"
      zIndex={1}
      opacity={0}
      css={`
        pointer-events: ${hasNoMagnets ? 'auto' : 'none'};
        transition: opacity ${p => p.theme.transition};
        transform: translate(-50%, -50%) rotate(-2deg);
      `}
    >
      Create a message first!
    </MagnetStrip>
  </Flex>
)

const mapStateToProps = state => ({
  hasNoMagnets: compose(
    isEmpty,
    get('magnets.positions')
  )(state),
})

export const SendButton = connect(mapStateToProps)(SendButtonBase)
