import React from 'react'
import { findDOMNode } from 'react-dom'
import { DropTarget } from 'react-dnd'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Rect from '@reach/rect'
import { get, compose, debounce, noop, pick } from 'lodash/fp'

import { setPosition } from 'src/modules/magnets'
import { setDimensions } from 'src/modules/refrigerator'
import { ItemTypes } from 'src/constants'
import { Box } from 'src/components/system'

const dropzoneTarget = {
  drop(props, monitor) {
    const {
      setPosition,
      dropTargetWidth: width,
      dropTargetHeight: height,
      dropTargetTop: top,
      dropTargetX: x,
    } = props
    const item = monitor.getItem()
    const offset = monitor.getSourceClientOffset()

    console.log({ props, monitor, item, offset })

    setPosition({
      id: get('id', item),
      x: (offset.x - x) / width,
      y: (offset.y - top) / height,
    })
  },
}

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
})

const DropzoneWrapperBase = ({
  children,
  connectDropTarget,
  isOver = false,
  setDimensions = noop,
  setPosition = noop,
  dropTargetWidth = 1,
  dropTargetHeight = 1,
  dropTargetTop = 1,
  ...props
}) => (
  <Rect
    onChange={debounce(
      250,
      compose(
        setDimensions,
        pick(['width', 'height', 'top', 'x', 'y'])
      )
    )}
  >
    {({ rect, ref }) => (
      <Box
        ref={instance => {
          compose(
            connectDropTarget,
            findDOMNode
          )(instance)
          ref(instance)
        }}
        cursor={isOver ? 'grabbing' : 'auto'}
      >
        {children({ isOver })}
      </Box>
    )}
  </Rect>
)

export const DropzoneWrapperDnd = DropTarget(
  ItemTypes.MAGNET_STRIP,
  dropzoneTarget,
  collect
)(DropzoneWrapperBase)

const mapStateToProps = state => ({
  dropTargetWidth: get('refrigerator.width', state),
  dropTargetHeight: get('refrigerator.height', state),
  dropTargetTop: get('refrigerator.top', state),
  dropTargetX: get('refrigerator.x', state),
  dropTargetY: get('refrigerator.y', state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setPosition, setDimensions }, dispatch)

export const DropzoneWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(DropzoneWrapperDnd)
