import React, { useEffect } from 'react'
import Carousel from 'nuka-carousel'

import { isBrowser } from 'src/helpers'
import { Box, Flex, Image, Button } from 'src/components/system'
import { Link } from 'src/components/Link'
import { SVG } from 'src/components/SVG'
import { ReactComponent as AssetIconChevronLeftSVG } from 'src/assets/icon-chevron-left.svg'
import { ReactComponent as AssetIconChevronRightSVG } from 'src/assets/icon-chevron-right.svg'
import AssetMediaCard1 from 'src/assets/media-card-1.jpg'
import AssetMediaCard2 from 'src/assets/media-card-2.jpg'
import AssetMediaCard3 from 'src/assets/media-card-3.jpg'
import AssetMediaCard4 from 'src/assets/media-card-4.jpg'
import AssetMediaCard5 from 'src/assets/media-card-5.jpg'
import AssetMediaCard6 from 'src/assets/media-card-6.jpg'

const Card = ({ to, src, ...props }) => (
  <Box width={['10rem', 1 / 6]} mr={['auto', 4]} ml={['auto', 0]} {...props}>
    <Link to={to} target="_blank" rel="nofollow noreferrer">
      <Image src={src} width="100%" />
    </Link>
  </Box>
)

export const OldFashionedCardsCarousel = props => {
  useEffect(() => {
    if (!isBrowser) return

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    })
  }, [])

  return (
    <Box {...props}>
      <Box display={[null, 'none']}>
        <Carousel
          renderBottomCenterControls={() => null}
          renderCenterLeftControls={({ previousSlide }) => (
            <Button onClick={previousSlide}>
              <SVG
                svg={AssetIconChevronLeftSVG}
                x={18.9}
                y={35.64}
                width="1.5rem"
                fill="tealExtraLight"
              />
            </Button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <Button onClick={nextSlide}>
              <SVG
                svg={AssetIconChevronRightSVG}
                x={18.9}
                y={35.64}
                width="1.5rem"
                fill="tealExtraLight"
              />
            </Button>
          )}
          wrapAround={true}
        >
          <Card
            to="https://shop.walltowall.com/products/occasional-cards-volume-1"
            src={AssetMediaCard1}
          />
          <Card
            to="https://shop.walltowall.com/products/occasional-cards-volume-2"
            src={AssetMediaCard2}
          />
          <Card
            to="https://shop.walltowall.com/products/occasional-cards-volume-3"
            src={AssetMediaCard3}
          />
          <Card
            to="https://shop.walltowall.com/products/occasional-cards-volume-4"
            src={AssetMediaCard4}
          />
          <Card
            to="https://shop.walltowall.com/products/occasional-cards-volume-5"
            src={AssetMediaCard5}
          />
          <Card
            to="https://shop.walltowall.com/products/occasional-cards-vol-6"
            src={AssetMediaCard6}
          />
        </Carousel>
      </Box>
      <Flex
        display={['none', 'flex']}
        justifyContent="center"
        mx="auto"
        maxWidth="xlarge"
      >
        <Card
          to="https://shop.walltowall.com/products/occasional-cards-volume-1"
          src={AssetMediaCard1}
        />
        <Card
          to="https://shop.walltowall.com/products/occasional-cards-volume-2"
          src={AssetMediaCard2}
        />
        <Card
          to="https://shop.walltowall.com/products/occasional-cards-volume-3"
          src={AssetMediaCard3}
        />
        <Card
          to="https://shop.walltowall.com/products/occasional-cards-volume-4"
          src={AssetMediaCard4}
        />
        <Card
          to="https://shop.walltowall.com/products/occasional-cards-volume-5"
          src={AssetMediaCard5}
        />
        <Card
          to="https://shop.walltowall.com/products/occasional-cards-vol-6"
          src={AssetMediaCard6}
          mr={0}
        />
      </Flex>
    </Box>
  )
}
