import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { get, reject, compose, find, propEq } from 'lodash/fp'

import { removePosition } from 'src/modules/magnets'
import { UnusedMagnets } from 'src/components/UnusedMagnets'

const mapStateToProps = state => ({
  magnets: compose(
    reject(x =>
      compose(
        find(propEq('id', get('id', x))),
        get('magnets.positions')
      )(state)
    ),
    get('magnets.items')
  )(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ removePosition }, dispatch)

export const UnusedMagnetsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnusedMagnets)
