import React, { useState } from 'react'

import { Box } from 'src/components/system'
import { SVG } from 'src/components/SVG'

import { ReactComponent as AssetMediaSnailSVG } from 'src/assets/media-snail.svg'

export const Snail = props => {
  const [didClickSnail, setDidClickSnail] = useState(false)
  const handleSnailClick = () => setDidClickSnail(true)

  return (
    <Box id="snail" mb={[2, 6]} position="relative" {...props}>
      <Box
        display="inline-block"
        backgroundImage="linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3)"
        backgroundSize="100vw"
        height={[1.5, 2]}
        width={0.75}
        position="absolute"
        bottom={0}
        left={['calc(50% - 1.75rem)', 'calc(50% - 2.5rem)']}
        css={`
          transform: scaleX(${didClickSnail ? 1 : 0});
          transition: transform 100s;
          transform-origin: left top;
        `}
      />
      <SVG
        svg={AssetMediaSnailSVG}
        x={91}
        y={59}
        cursor="pointer"
        width={['4rem', '6rem']}
        onClick={handleSnailClick}
        mx="auto"
        css={`
          transform: translateX(calc(${didClickSnail ? 1 : 0} * 75vw));
          transition: transform 100s;
        `}
      />
    </Box>
  )
}
