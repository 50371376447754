import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { get } from 'lodash/fp'

import { Box } from 'src/components/system'
import { Link } from 'src/components/Link'
import { GatsbyImage } from 'src/components/GatsbyImage'

const render = props => queryData => (
  <Box as="header" {...props}>
    <Box width={['15rem', '20rem', '30rem']} mx="auto" position="relative">
      <GatsbyImage fluid={get('file.childImageSharp.fluid', queryData)} />
      <Box
        as={Link}
        to="https://walltowall.com"
        target="_blank"
        rel="nofollow noreferrer"
        position="absolute"
        width="3rem"
        height="3rem"
        bottom={[0, null, '2rem']}
        right={0}
        css={`
          transform: translateY(-75%) translateX(15%);
        `}
      />
    </Box>
  </Box>
)

export const Header = props => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={render(props)}
  />
)
