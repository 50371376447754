import React from 'react'

import { Subheading as SubheadingBase } from 'src/components/system'

export const Subheading = props => (
  <SubheadingBase
    fontSize={['medium', 'midLarge']}
    color="white"
    lineHeight="title"
    {...props}
  />
)
