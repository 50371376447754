import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'
import html2canvas from 'html2canvas'
import axios from 'axios'
import { get } from 'lodash/fp'

import { setPhotoURL } from 'src/modules/output'
import { ButtonCircle } from 'src/components/ButtonCircle'

const OutputGeneratorButtonBase = ({
  setPhotoURL,
  ...props
}) => {
  let dataUrl = ''

  const handleClick = async () => {
    const dropzoneEl = document.querySelector('#dropzone')
    const canvasOutput = await html2canvas(dropzoneEl)
    dataUrl = canvasOutput.toDataURL('image/png')

    const formData = new FormData()
    formData.append('file', dataUrl)
    formData.append('upload_preset', process.env.CLOUDINARY_PRESET)
    const fetchURL = `https://api.cloudinary.com/v1_1/${
      process.env.CLOUDINARY_CLOUDNAME
    }/image/upload`

    try {
      const result = await axios.post(fetchURL, formData)
      setPhotoURL(get('data.secure_url', result))      
      navigate('/output')
    } catch (error) {
      console.error(error)
    }
  }

  return <ButtonCircle onClick={handleClick}>Send</ButtonCircle>
}

const mapStateToProps = state => ({
  photoURL: state.output.photoURL,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setPhotoURL }, dispatch)

export const OutputGeneratorButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(OutputGeneratorButtonBase)
