import React from 'react'

import { Button as ButtonBase } from 'src/components/system'

export const Button = ({ children, fixBaseline = true, ...props }) => (
  <ButtonBase
    border={2}
    borderColor="tealExtraLight"
    color="white"
    cursor="pointer"
    fontFamily="sansCond"
    fontSize="large"
    px={4}
    py={2}
    textAlign="center"
    textStyle="smallCaps"
    css={`
      transition: border-color ${p => p.theme.transition};
      &:hover {
        border-color: ${p => p.theme.colors.white};
      }
    `}
    {...props}
  >
    {children}
  </ButtonBase>
)
