import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { get } from 'lodash/fp'

import { setBackground } from 'src/modules/refrigerator'

import { Box, Flex, Button, Image } from 'src/components/system'

import AssetBackground1Thumb from 'src/assets/background-1-thumb.png'
import AssetBackground2Thumb from 'src/assets/background-2-thumb.png'
import AssetBackground3Thumb from 'src/assets/background-3-thumb.png'
import AssetBackground4Thumb from 'src/assets/background-4-thumb.png'
import AssetBackground1ThumbSelected from 'src/assets/background-1-thumb-selected.png'
import AssetBackground2ThumbSelected from 'src/assets/background-2-thumb-selected.png'
import AssetBackground3ThumbSelected from 'src/assets/background-3-thumb-selected.png'
import AssetBackground4ThumbSelected from 'src/assets/background-4-thumb-selected.png'

const backgroundThumbs = {
  1: AssetBackground1Thumb,
  '1-selected': AssetBackground1ThumbSelected,
  2: AssetBackground2Thumb,
  '2-selected': AssetBackground2ThumbSelected,
  3: AssetBackground3Thumb,
  '3-selected': AssetBackground3ThumbSelected,
  4: AssetBackground4Thumb,
  '4-selected': AssetBackground4ThumbSelected,
}

const BackgroundButton = ({
  background,
  isActive,
  setBackground,
  ...props
}) => (
  <Box
    as="li"
    boxStyle="lastNoMargin"
    maxWidth="6rem"
    mb={[null, 2]}
    px={1}
    width={[1 / 5, 1 / 2]}
  >
    <Button
      onClick={() => setBackground(background)}
      cursor="pointer"
      width="100%"
    >
      <Image
        src={get(
          isActive ? `${background}-selected` : background,
          backgroundThumbs
        )}
        width="100%"
      />
    </Button>
  </Box>
)

export const BackgroundChooserBase = ({
  background,
  setBackground,
  ...props
}) => (
  <Flex
    as="ul"
    justifyContent={['center', 'flex-end']}
    flexWrap={[null, 'wrap']}
    {...props}
  >
    <BackgroundButton
      background={1}
      setBackground={setBackground}
      isActive={background === 1}
    />
    <BackgroundButton
      background={2}
      setBackground={setBackground}
      isActive={background === 2}
    />
    <BackgroundButton
      background={3}
      setBackground={setBackground}
      isActive={background === 3}
    />
    <BackgroundButton
      background={4}
      setBackground={setBackground}
      isActive={background === 4}
    />
  </Flex>
)

const mapStateToProps = state => ({
  background: get('refrigerator.background', state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setBackground }, dispatch)

export const BackgroundChooser = connect(
  mapStateToProps,
  mapDispatchToProps
)(BackgroundChooserBase)
