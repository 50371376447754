import React from 'react'
import Image from 'gatsby-image'

import { Box } from 'src/components/system'

export const GatsbyImage = ({
  objectFit = 'cover',
  objectPosition = '50% 50%',
  ...props
}) => (
  <Box
    as={Image}
    {...props}
    imgStyle={{
      ...props.imgStyle,
      objectFit,
      objectPosition,
      fontFamily: `"object-fit: ${objectFit}; object-position: ${objectPosition}"`,
    }}
  />
)
