import React from 'react'

import { Box } from 'src/components/system'
import AssetPatternDiagonalLinesThick from 'src/assets/pattern-diagonal-lines-thick.png'

export const RuleDiagonalPattern = props => (
  <Box
    backgroundImage={`url(${AssetPatternDiagonalLinesThick})`}
    backgroundSize="auto 100%"
    backgroundPosition="center center"
    height={['2rem', '4rem']}
    {...props}
  />
)
