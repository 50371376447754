import React from 'react'

import { Box, Flex } from 'src/components/system'
import { SVG } from 'src/components/SVG'
import { ReactComponent as AssetRuleEndRightSVG } from 'src/assets/rule-end-curly-right.svg'

export const RuleCurly = ({
  direction = 'horizontal',
  sideX = 'right',
  sideY = 'top',
  ...props
}) => (
  <Flex
    flexDirection={direction === 'horizontal' ? 'row' : 'column'}
    alignItems={sideY === 'top' ? 'flex-end' : 'flex-start'}
    {...props}
  >
    <Box
      height={2}
      bg="tealExtraLight"
      flex="1 1 auto"
      order={sideX === 'right' ? 1 : 2}
    />
    <SVG
      svg={AssetRuleEndRightSVG}
      x={27}
      y={37}
      width={27}
      flex="0 0 auto"
      order={sideX === 'right' ? 2 : 1}
      css={`
        transform: scale(
          ${sideX === 'right' ? 1 : -1},
          ${sideY === 'top' ? 1 : -1}
        );
      `}
    />
  </Flex>
)
