import React from 'react'
import { DragDropContextProvider } from 'react-dnd'
import TouchBackend from 'react-dnd-touch-backend'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { generateMessage } from 'src/modules/magnets'

import { Box, Flex } from 'src/components/system'
import { BackgroundChooser } from 'src/components/BackgroundChooser'
import { Button } from 'src/components/Button'
import { ButtonCircle } from 'src/components/ButtonCircle'
import { DragPreview } from 'src/components/DragPreview'
import { DropzoneWrapper } from 'src/components/DropzoneWrapper'
import { Header } from 'src/components/Header'
import { Heading } from 'src/components/Heading'
import { Layout } from 'src/components/Layout'
import { Link } from 'src/components/Link'
import { SendButton } from 'src/components/SendButton'
import { MagnetStrip } from 'src/components/MagnetStrip'
import { OldFashionedCardsCarousel } from 'src/components/OldFashionedCardsCarousel'
import { OutputGeneratorButton } from 'src/components/OutputGeneratorButton'
import { OutputRenderer } from 'src/components/OutputRenderer'
import { RuleCurly } from 'src/components/RuleCurly'
import { RuleDiagonalPattern } from 'src/components/RuleDiagonalPattern'
import { Subheading } from 'src/components/Subheading'
import { Snail } from 'src/components/Snail'
import { UnusedMagnetsContainer } from 'src/containers/UnusedMagnetsContainer'

const IndexPageBase = ({ generateMessage, ...props }) => (
  <Layout>
    <DragDropContextProvider
      backend={TouchBackend({
        enableMouseEvents: true,
        delayTouchStart: 40,
      })}
    >
      <Header mt={[4, 8]} mb={2} px={4} />
      <Flex mb={[2, 3]}>
        <RuleCurly sideX="right" mr={[2, 3]} flex="1 1 0px" />
        <RuleCurly sideX="left" flex="1 1 0px" />
      </Flex>
      <Flex
        alignItems={[null, 'center']}
        borderBottom={[null, 2]}
        borderColor={[null, 'tealExtraLight']}
        borderTop={[null, 2]}
        flexDirection={['column', 'row']}
      >
        <Box
          flex={[null, '0 0 auto']}
          order={[null, 2]}
          py={[null, 4]}
          width={['100%', 400, 600, 800]}
        >
          <DropzoneWrapper>
            {({ isOver }) => (
              <OutputRenderer magnetsDraggable={true} isOver={isOver} />
            )}
          </DropzoneWrapper>
        </Box>
        <UnusedMagnetsContainer
          flex={[null, '1 1 0px']}
          height={[84, 464, 664, 864]}
          ml={[null, 4, null, 8]}
          order={[null, 3]}
        />
        <Flex
          order={[null, 1]}
          flexDirection="column"
          alignItems={['center', 'flex-end']}
          flex={[null, '1 1 0px']}
          py={[3, 4]}
          pl={[null, 4]}
          pr={[null, 4, null, 8]}
        >
          <Button id="randomizer" onClick={generateMessage} mb={[2, 8]}>
            Surprise Me
          </Button>
          <Subheading mb={2} textAlign={['center', 'right']}>
            Choose a Background
          </Subheading>
          <BackgroundChooser
            width={['100%', '75%']}
            maxWidth={[null, '12rem']}
          />
        </Flex>
      </Flex>
      <Flex
        alignItems="flex-start"
        borderColor="tealExtraLight"
        borderTop={[2, 0]}
        pt={[2, 3]}
      >
        <RuleCurly sideX="right" sideY="bottom" flex="1 1 0px" />
        <SendButton />
        <RuleCurly sideX="left" sideY="bottom" flex="1 1 0px" />
      </Flex>
      <RuleDiagonalPattern my={[6, 8]} />
      <Flex px={[2, 6]} mb={[6, 8]} flexDirection="column" alignItems="center">
        <Snail alignSelf="stretch" mx={[-2, -6]} />
        <Heading textAlign="center" mb={[2, 4]} maxWidth="30rem">
          Greetings the good old fashioned way
        </Heading>
        <OldFashionedCardsCarousel alignSelf="stretch" mb={[4, 6]} />
        <Button
          as={Link}
          to="https://shop.walltowall.com"
          target="_blank"
          rel="nofollow noreferrer"
        >
          Visit our Occasional Shop
        </Button>
      </Flex>
      <DragPreview />
    </DragDropContextProvider>
  </Layout>
)

const mapDispatchToProps = dispatch =>
  bindActionCreators({ generateMessage }, dispatch)

const IndexPage = connect(
  null,
  mapDispatchToProps
)(IndexPageBase)

export default IndexPage
