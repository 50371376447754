import React from 'react'
import { findDOMNode } from 'react-dom'
import { DropTarget } from 'react-dnd'
import { get, map, compose } from 'lodash/fp'

import { ItemTypes } from 'src/constants'
import { Box } from 'src/components/system'
import { DraggableMagnetStrip } from 'src/components/DraggableMagnetStrip'

const unusedMagnetsTarget = {
  drop(props, monitor) {
    const { removePosition } = props
    const item = monitor.getItem()

    removePosition({
      id: get('id', item),
    })
  },
}

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
})

const UnusedMagnetsBase = ({
  connectDropTarget,
  isOver = false,
  magnets,
  ...props
}) => (
  <Box
    bg={['tealExtraLight', 'transparent']}
    overflowX={['scroll', 'hidden']}
    overflowY={['hidden', 'scroll']}
    css={`
      -webkit-overflow-scrolling: touch;
    `}
    {...props}
  >
    <Box
      ref={compose(
        connectDropTarget,
        findDOMNode
      )}
      cursor={isOver ? 'grabbing' : 'auto'}
      display={['flex', 'block']}
      p={[2, 0]}
    >
      {map(
        magnet => (
          <Box
            display={['inline-block', 'block']}
            mr={[2, 0]}
            mb={[null, 2]}
            boxStyle="lastNoMargin"
          >
            <DraggableMagnetStrip
              key={get('id', magnet)}
              id={get('id', magnet)}
              boxStyle="lastNoMargin"
            >
              {get('value', magnet)}
            </DraggableMagnetStrip>
          </Box>
        ),
        magnets
      )}
    </Box>
  </Box>
)

export const UnusedMagnets = DropTarget(
  ItemTypes.MAGNET_STRIP,
  unusedMagnetsTarget,
  collect
)(UnusedMagnetsBase)
