import React from 'react'

import { Flex, Button as ButtonBase } from 'src/components/system'
import { AspectRatio } from 'src/components/AspectRatio'

export const ButtonCircle = ({
  children,
  minWidth = ['8rem', '9rem'],
  mr = 0,
  ...props
}) => (
  <AspectRatio x={1} y={1} display="inline-block" minWidth={minWidth} mr={mr}>
    <ButtonBase
      border={2}
      borderColor="tealExtraLight"
      borderRadius="50%"
      color="white"
      cursor="pointer"
      fontFamily="sansCond"
      fontSize="large"
      height="100%"
      p={2}
      textAlign="center"
      textStyle="smallCaps"
      width="100%"
      css={`
        transition: border-color ${p => p.theme.transition};
        &:hover {
          border-color: ${p => p.theme.colors.white};
        }
      `}
      {...props}
    >
      <Flex
        alignItems="center"
        height="100%"
        justifyContent="center"
        width="100%"
        css={`
          transform: translateY(2%);
        `}
      >
        {children}
      </Flex>
    </ButtonBase>
  </AspectRatio>
)
